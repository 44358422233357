<template>
  <div class="card-body pt-0 pb-3 pt-7 card card-custom gutter-b">
    <!--begin::Table-->
    <div class="table-responsive">
      <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
        <thead>
          <tr>
            <th class="table-mw-100 pl-7">Image</th>
            <th class="table-mw-100 text-center">Name</th>
            <th class="table-mw-100 text-center">Link</th>
            <th class="table-mw-100 text-center"></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, i) in list">
            <tr v-bind:key="i" class="text-center">
              <td class="pl-0 pt-8 table-mw-100">
                <div class="d-flex align-items-center">
                  <div class="symbol symbol-130 symbol-light mr-4">
                    <span class="symbol-label">
                      <img :src="`${base_url}/uploads/default/${item.photoUrl}`" class="h-100 align-self-end" alt="" />
                    </span>
                  </div>
                </div>
              </td>
              <td class="table-mw-100">
                <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{ item.headline }}</span>
              </td>
              <td class="table-mw-100">
                <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{ item.link }}</span>
              </td>

              <td class="pr-0 text-right table-mw-100">
                <b-button
                  class="btn btn-light-success font-weight-bolder font-size-sm ml-3 mt-4"
                  @click="openModal(item)"
                >
                  <i class="fas fa-pencil-alt fa-1x mr-1"></i>Edit</b-button
                >
                <!-- <router-link class="btn btn-light-success font-weight-bolder font-size-sm mt-4" to="/sponsors/new">
                  <i class="fas fa-pencil-alt fa-1x mr-1"></i>Edit</router-link
                > -->
                <b-button
                  class="btn btn-light-danger font-weight-bolder font-size-sm ml-3 mt-4"
                  @click="deleteAction(item)"
                >
                  <i class="fas fa-trash fa-1x mr-1"></i>Delete</b-button
                >
              </td>
            </tr>
          </template>
          <sc-update-sponsor-modal :sponsorDetail="selectedSponsor" id="modal-1" ref="updateSponsorModal" />
        </tbody>
      </table>
      <b-pagination
        v-model="currentPage"
        :per-page="perPage"
        aria-controls="my-table"
        align="center"
        class="mt-10"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { base_url } from '../../../const'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import SponsorServices from '../../../core/services/sponsors'
import scUpdateSponsorModal from '../../content/widgets/sc-update-sponsor-modal.vue'
import Swal from 'sweetalert2'

export default {
  components: { scUpdateSponsorModal },
  name: 'sponsors-overview',
  data() {
    return {
      currentPage: 1,
      perPage: 8,
      list: [
        {
          img: 'media/svg/avatars/001-boy.svg',
          name: 'scooble UG',
          link: 'www.scooble.de',
        },
      ],
      base_url,
      selectedSponsor: [],
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Sponsors' }])
    this.getSponsors()
  },
  methods: {
    async getSponsors() {
      const getSponsors = (await SponsorServices.getAll()).data
      console.log(`getSponsors`, getSponsors)
      this.list = getSponsors
    },
    openModal(e) {
      console.log(`e`, e)
      //access the childComponent via ref
      //access its children and use the correct index for
      //your targeted boostrap vue modal
      //use the show property which is the boostrap show method
      this.selectedSponsor = e
      console.log(`this.$refs.updateSponsorModal.$children`, this.$refs.updateSponsorModal.$children)
      this.$refs.updateSponsorModal.$children[0].show()
      //sometimes it helps to console log the ref to see all
      //available properties of ref element and its children
    },
    async deleteAction(item) {
      console.log(`item`, item)
      const currentUser = localStorage.getItem('scooble_user')
      const deletedSponsor = (await SponsorServices.delete(item._id, JSON.parse(currentUser)._id)).data
      console.log(`deletedSponsor`, deletedSponsor)
      if (deletedSponsor.deletedCount >= 0) {
        const newList = this.list.filter(q => q._id !== item._id)
        this.list = newList
      } else {
        return Swal.fire({
          title: 'unexpected error',
          text: 'there was an unexpected error',
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary',
        })
      }
    },
  },
}
</script>

<style>
.table-mw-150 {
  min-width: 150px;
}
.table-mw-100 {
  min-width: 100px;
}
.mb-25 {
  margin-bottom: 25px !important;
}
</style>
