<template>
  <b-modal
    hide-backdrop
    content-class="shadow"
    ref="modal-1"
    id="modal-1"
    title="Sponsor Edit"
    scrollable
    hide-footer
    size="lg"
  >
    <b-card footer-tag="footer" footer-class="text-right">
      <b-form-group class="text-muted">
        <div class="image-input image-input-outline" :style="{ backgroundImage: `url(${photo})` }">
          <div class="image-input-wrapper text-left"></div>
          <label
            class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
            data-action="change"
            data-toggle="tooltip"
            title=""
          >
            <i class="fa fa-pen icon-sm text-muted"></i>
            <input type="file" accept=".png, .jpg, .jpeg" @change="onFileChange($event)" />
          </label>
        </div>
      </b-form-group>
      <b-form-group class="text-muted" label="Sponsor Headline">
        <b-form-input v-model="item.headline"></b-form-input>
      </b-form-group>
      <b-form-group class="text-muted" label="Sponsor Desc">
        <b-form-textarea rows="8" v-model="item.text" :value="item.text"></b-form-textarea>
      </b-form-group>
      <b-form-group class="text-muted" label="Button Text">
        <b-form-input v-model="item.buttonText"></b-form-input>
      </b-form-group>
      <b-form-group class="text-muted" label="Button Link">
        <b-form-input v-model="item.buttonLink"></b-form-input>
      </b-form-group>
      <b-button variant="primary" class="font-weight-bold px-10" block @click="saveChanges"> Save Changes </b-button>

      <b-button class="mt-3" block @click="$bvModal.hide('modal-1')">Close Me</b-button>
    </b-card>
  </b-modal>
</template>

<script>
import { base_url } from '../../../const'

export default {
  name: 'sc-update-sponsor-modal',
  props: ['sponsorDetail'],
  data() {
    return {
      item: '',
      text: '',
      headline: '',
      buttonText: '',
      buttonLink: '',
      editorConfig: {
        // The configuration of the editor.
      },
      default_photo: '',
      current_photo: null,
    }
  },
  mounted() {
    console.log(`sponsorDetail`, this.sponsorDetail)
    this.default_photo = `${base_url}/uploads/default/${this.currentUser.photoUrl}`
    console.log(`this.default_photo`, this.default_photo)
  },
  computed: {
    photo() {
      return this.current_photo == null ? this.default_photo : this.current_photo
    },
  },
  watch: {
    sponsorDetail: function (params) {
      console.log(`params`, params)
      this.default_photo = `${base_url}/uploads/default/${params.photoUrl}`
      this.item = params
    },
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0]

      if (typeof FileReader === 'function') {
        const reader = new FileReader()

        reader.onload = event => {
          this.current_photo = event.target.result
        }

        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
  },
}
</script>
